import React from "react"
import Layout from "src/components/layout"
import SEO from 'src/components/seo'
import AnalyticsHelmet from 'src/components/AnalyticsHelmet'
import { trackPage, } from 'src/utilities/analytics'

// inspired by https://resume.io/tos
const Content = () => {
  const companyName = '80/20 Solutions, LLC'
  const companyEmail = 'support@nextlevelresume.co'
  const supportURL = `${process.env.BASE_URL}/support`
  const jurisdiction = 'Californian'
  const lastUpdated = 'February 18, 2021'

  const CompanyAddress = ({className}) => {
    return (
      <div {...{className}}>
        <p>{companyName}</p>
        <p>1965 Rodney Drive</p>
        <p>Suite 203</p>
        <p>Los Angeles, CA 90027</p>
      </div>
    )
  }
  const CompanyContactInfo = ({className}) => {
    return (
      <div {...{className}}>
        <CompanyAddress/>
        <p>{companyEmail}</p>
      </div>
      )
  }

  return (
    <div>
      <h1 className="display-4 mb-2">Terms of Service</h1>
      <h3 id="article-1-definitions"><strong>Article 1 Definitions</strong></h3>
      <ol>
        <li>In these Terms of Use, the terms below are defined as follows:</li>
      </ol>
      <p><strong>Account:</strong> the Customer’s personal environment on the Platform, which he gains access to by using the login details.</p>
      <p><strong>Content:</strong> all information, including but not limited to personal data, and documents provided or shared by the Customer in any way via the Platform, including but not limited to documents and data that are uploaded.</p>
      <p><strong>Services:</strong> the services provided by {companyName} to the Customer via the Platform.</p>
      <p><strong>Documentation:</strong> the written and/or electronic documentation relevant to the Platform and the Services.</p>
      <p><strong>Terms of Use:</strong> these general terms and conditions and terms of use of {companyName} that apply to the Agreement between {companyName} and the customer and to the use of the Platform and the Services.</p>
      <p><strong>Login details:</strong> the user name, the password and any additional security information for the Customer, which he can use to gain access to his Account(s).</p>
      <p><strong>Customer:</strong> the natural person who does not act in the performance of a profession or business.</p>
      <p><strong>Agreement:</strong> the agreement between {companyName} and the Customer with respect to the Platform and the<br/>
      &nbsp; Services.</p>
      <p><strong>Parties:</strong> {companyName} and Customer jointly, each individually referred to as a ‘Party’.</p>
      <p><strong>Platform:</strong> the digital environment provided by {companyName} to the Customer on the basis of software as a service, which contains the functionalities shown in the Documentation.</p>
      <p><strong>Privacy</strong> this includes the personal data that {companyName} may collect during use of the Platform and the</p>
      <p><strong>Policy:</strong> Service, the purposes for which the relevant personal data are processed, the security measures and the rights of the controller and the data subject. The Privacy Conditions shall furthermore apply as a processor agreement between the parties.</p>
      <p><strong>{companyName}:</strong></p>
      <CompanyContactInfo/>
      <h3 id="article-2-applicability-of-terms-of-use-and-amendments"><strong>Article 2 Applicability of Terms of Use and amendments</strong>&nbsp;</h3>
      <ol>
      <li>
      <p>These Terms of Use apply to the Agreement between {companyName} and the Customer, unless these Terms of Use are expressly deviated from writing.</p>
      </li>
      <li>
      <p>{companyName} expressly excludes the applicability of additional or other (general) terms and conditions, unless {companyName} and the Customer explicitly agree otherwise in writing.</p>
      </li>
      <li>
      <p>The Agreement is concluded electronically. {companyName} will provide the Terms of Use electronically to the Customer prior to the conclusion of the Agreement and in such a way that it can be easily stored by the Customer on a durable medium. If this is not reasonably possible, the location where the Terms of Use can be accessed electronically will be indicated before the Agreement is concluded. The Terms of Use may also be sent to the Customer free of charge at his request.</p>
      </li>
      <li>
      <p>In the event that specific product or service terms and conditions apply in addition to these Terms of Use, the second and third paragraphs apply mutatis mutandis and, in the event of conflicting terms and conditions, the Customer may at all times invoke the applicable provision that benefits him most.</p>
      </li>
      </ol>
      <h3 id="article-3-tendersoffer"><strong>Article 3 Tenders/Offer&nbsp;</strong></h3>
      <ol>
      <li>
      <p>The offer contains a complete and accurate description of the offered products, digital content and/or services. The description shall be sufficiently specified to allow the Customer a proper assessment of the offer. Where {companyName} uses images, they are a true reflection of the offered products, services and/or digital content. Apparent mistakes or manifest errors in the offer do not bind {companyName}.</p>
      </li>
      <li>
      <p>Each offer contains information to such extent that the rights and obligations attached to the acceptance of the offer are evident to the Customer.</p>
      </li>
      <li>
      <p>Apparent mistakes or errors in a quote or offer do not bind {companyName}.&nbsp;</p>
      </li>
      </ol>
      <h3 id="article-4-agreement-between-{companyName}-and-the-customer"><strong>Article 4 Agreement between {companyName} and the Customer</strong></h3>
      <ol>
      <li>
      <p>Subject to the provisions of paragraph 4, the Agreement is concluded from the moment of acceptance by the Customer of the offer and the fulfilment of the corresponding conditions.</p>
      </li>
      <li>
      <p>If the Customer has accepted the offer electronically, {companyName} will immediately confirm receipt of the acceptance of the offer electronically. As long as the receipt of this acceptance has not been confirmed by {companyName}, the Customer may terminate the Agreement.</p>
      </li>
      <li>
      <p>If the Agreement is concluded electronically, {companyName} will take appropriate technical and organisational measures to secure the electronic transfer of data and ensure a secure web environment. If the Customer pays electronically, {companyName} will take appropriate security measures to that end.</p>
      </li>
      <li>
      <p>{companyName} may, within the law, verify whether the Customer is able to meet his payment obligations, as well as all those facts and factors that are important for a responsible conclusion of the distance contract. If, based on such investigation, {companyName} has good reasons not to enter into the Agreement, it is entitled to refuse an order or request stating reasons, or to attach special conditions to the execution.</p>
      </li>
      <li>
      <p>No later than upon delivery of the service to the Customer, {companyName} shall supply the following information, in writing or in such a way that it can be stored by the Customer in an accessible manner on a durable medium:</p>
      </li>
      <li>
      <p>the postal address of the {companyName} branch where the Customer can submit its written complaints;</p>
      </li>
      <li>
      <p>the conditions under which and the manner in which the Customer can exercise the right of withdrawal, or a clear statement regarding the exclusion of the right of withdrawal;</p>
      </li>
      <li>
      <p>the information about guarantees and existing after-sales service;</p>
      </li>
      <li>
      <p>the price including all taxes of the product, service or digital content; where applicable, the costs of delivery; and the method of payment, delivery or execution of the distance contract;</p>
      </li>
      <li>
      <p>the requirements for cancellation of the contract if the contract has a duration of more than one year or is indefinite;</p>
      </li>
      <li>
      <p>In the event of renewal of the agreement, the provision in paragraph 1 only applies when the Platform is made available for the first time.&nbsp;</p>
      </li>
      </ol>
      <h3 id="article-5-restriction-of-right-of-withdrawal"><strong>Article 5 Restriction of right of withdrawal</strong></h3>
      <ol>
      <li>The Customer expressly agrees that the right of withdrawal is limited to a 3- (three) day period.</li>
      </ol>
      <h3 id="article-6-user-rights-and-access"><strong>Article 6 User rights and access&nbsp;</strong></h3>
      <ol>
      <li>
      <p>Upon the conclusion of the Agreement, or as much later as {companyName} and the Customer have agreed, {companyName} shall provide the Customer with the login details, to be used for access to Account on the Platform and the Services.</p>
      </li>
      <li>
      <p>By sending the login details, {companyName} grants the Customer a non-exclusive, personal and non-transferable right of use of the Platform and the Services with effect from and for the duration of the Agreement.</p>
      </li>
      <li>
      <p>The login details and the use of the Platform and the Services are strictly personal. The Customer must observe strict confidentiality and secrecy with regard to his login details.</p>
      </li>
      <li>
      <p>The Customer will take appropriate measures to detect, limit or prevent loss and/or abuse of login details.</p>
      </li>
      <li>
      <p>Loss or misuse of the login details and/or abuse of the Platform must be reported to {companyName} immediately. {companyName} is not liable for (the consequences of) loss of login details and/or the misuse of login details of the Customer by a third party.&nbsp;</p>
      </li>
      </ol>
      <h3 id="article-7-price"><strong>Article 7 Price&nbsp;</strong></h3>
      <ol>
      <li>
      <p>During the validity period stated in the offer, the prices of the services offered/the service offered will not be increased, except for price changes due to a change in taxes.</p>
      </li>
      <li>
      <p>Price increases within 3 (three) months after the conclusion of the contract are only permitted if they are the result of statutory regulations or provisions. The Customer is entitled to cancel the agreement with effect from the day on which the price increase takes effect.</p>
      </li>
      <li>
      <p>The prices mentioned in the services offer include taxes.</p>
      </li>
      <li>
      <p>The Customer will make the first payment via iDeal payment or according to the payment instructions on the website. Upon receipt of the confirmation of the Agreement from the Customer by {companyName}, the fourteen-day payment term commences. Upon renewal of the Agreement, the costs will be debited through a direct debit from the account number provided by the Customer.</p>
      </li>
      <li>
      <p>The Customer is obliged to immediately notify {companyName} of any inaccuracies in the stated payment details.</p>
      </li>
      <li>
      <p>The Customer is obliged to have sufficient balance on the account number provided by him to the extent that the direct debit can be paid.</p>
      </li>
      <li>
      <p>If the Customer fails to fulfil his payment obligation(s) on time, the Customer is, after having been notified by {companyName} of the late payment and after having been granted a period of 14 (fourteen) days to still fulfil his payment obligations, and the Customer still fails to pay within this 14-day period, due to pay the legal interest due on the outstanding amount and {companyName} shall be entitled to charge the extrajudicial collection costs incurred by it. These collection costs amount to a maximum of: 15% on outstanding amounts up to $2,500; 10% on the next $2,500 and 5% on the next $5,000 with a minimum of $40.&nbsp;</p>
      </li>
      </ol>
      <h3 id="article-8-delivery-times"><strong>Article 8 Delivery times</strong></h3>
      <ol>
      <li>
      <p>{companyName} makes sure to apply all due care when receiving the data from the Customer and when assessing the application for the provision of the Services.</p>
      </li>
      <li>
      <p>If the delivery of the Service is delayed in any way, or if the delivery of the Service cannot or only partially be made, the Customer will be notified no later than 30 (thirty) days after the order was placed. In that case, the Customer has the right to dissolve the agreement without any costs and shall be entitled to any compensation.</p>
      </li>
      <li>
      <p>After dissolution in accordance with the previous paragraph, {companyName} shall immediately repay the amount already paid by the Customer.&nbsp;</p>
      </li>
      </ol>
      <h3 id="article-9-rights-and-obligations-on-the-part-of-the-customer"><strong>Article 9 Rights and obligations on the part of the Customer&nbsp;</strong></h3>
      <ol>
      <li>
      <p>The Customer will timely provide all information necessary for {companyName} to execute the Agreement and provide his cooperation.</p>
      </li>
      <li>
      <p>The Customer is expressly not allowed to use the delivery made by {companyName} under the terms of the Agreement for a purpose other than the intended use.</p>
      </li>
      <li>
      <p>The Customer declares to use the Platform and the Services solely for his own account and risk. The customer is therefore responsible for the use of the Platform and the services and its user account by others.</p>
      </li>
      <li>
      <p>The Customer shall not sell, transfer or pledge his rights and obligations under the Agreement to a third party.</p>
      </li>
      <li>
      <p>The Customer is liable for all use of the Platform and the Services, including the use by those whom he has given access to his Account. The customer indemnifies {companyName} against all use of the Platform and the Services and all claims of third parties in respect of such use.</p>
      </li>
      <li>
      <p>The Customer may not monopolise the platform’s infrastructure and/or the functionality of the Platform unreasonably or disproportionately.</p>
      </li>
      <li>
      <p>The use of the Platform by the Customer and Content he uploads and downloads, may not infringe the rights, good name and interests of {companyName} and/or third parties, including but not limited to intellectual property rights and rights relating to the protection of personal data.</p>
      </li>
      <li>
      <p>The Content uploaded by the Customer must be provided in a manner and in a format as indicated by {companyName}.</p>
      </li>
      <li>
      <p>The Content uploaded by the Customer is owned and remains the property of the Customer.</p>
      </li>
      <li>
      <p>The Customer guarantees the correctness, completeness, accuracy, reliability and up-to-date status of the Content, even if it originates from third parties. {companyName} is not responsible and/or liable for the use of Content by the Customer.</p>
      </li>
      <li>
      <p>Without prejudice to the other provisions of these Terms of Use, the Customer may not upload Content that:</p>
      </li>
      <li>
      <p>is based on untruths and/or is misleading;</p>
      </li>
      <li>
      <p>is libellous, slanderous, offensive, racist, discriminatory or hateful;</p>
      </li>
      <li>
      <p>erotic or pornographic;</p>
      </li>
      <li>
      <p>contains hyperlinks, torrents or similar information of which the Customer is aware or ought to be aware that it refers to material that infringes third-party rights;</p>
      </li>
      <li>
      <p>contains personal data without the consent of data subjects or unnecessarily;</p>
      </li>
      <li>
      <p>contains unwanted commercial, charitable or idealistic communication;</p>
      </li>
      <li>
      <p>contains viruses, malware, Trojan horses, worms, bots or other software that damage, erase or appropriate an automated work or data or make automated work or data unusable or inaccessible or that is intended to bypass technical protection measures of the service and/or software;</p>
      </li>
      <li>
      <p>contains tools and/or applications (in-house or provided by third parties) used to investigate Content and/or the Platform in any way or used in an improper manner;</p>
      </li>
      <li>
      <p>is otherwise unlawful in any way.</p>
      </li>
      <li>
      <p>The customer is obliged to keep his own current (digital) copy of all information, data and Content that the Customer provides to {companyName} under the terms of the execution of the Agreement, whether or not via the Platform.</p>
      </li>
      <li>
      <p>Any use of the Platform and Services is entirely at the Customer’s own responsibility and at the expense and risk of the Customer.</p>
      </li>
      <li>
      <p>The Customer ensures the correct computer, data or telecommunication facilities with which the Platform can be used (safely) for his own account. {companyName} ensures that the user rights in the Platform are set correctly.</p>
      </li>
      <li>
      <p>The Customer can contact {companyName} via {supportURL} for support.</p>
      </li>
      </ol>
      <h3 id="article-10-{companyName}"><strong>Article 10 {companyName}&nbsp;</strong></h3>
      <ol>
      <li>
      <p>Unless the parties have agreed otherwise in writing, {companyName} only has a best-efforts obligation and no obligation to achieve results.</p>
      </li>
      <li>
      <p>With respect to the execution of the Agreement, {companyName} is entitled to engage third parties and to replace the employees concerned.</p>
      </li>
      <li>
      <p>{companyName} has the right to close the Platform and/or restrict access to it or its functionality in whole or in part, at any time, without stating reasons, for a definite or indefinite period, without being liable to pay damages to the Customer.</p>
      </li>
      <li>
      <p>{companyName} has the right at all times to limit and/or terminate the right to use the Platform and/or to block the Account and/or the Customer’s login details if the Customer acts in violation of a provision of these Terms of Use and/or the Agreement.</p>
      </li>
      <li>
      <p>{companyName} does not guarantee that the Platform will be free of defects and will perform without interruption.</p>
      </li>
      <li>
      <p>{companyName} does not guarantee that the Platform is available at all times and/or that the Platform performs at all times without errors.</p>
      </li>
      <li>
      <p>{companyName} may set a limit on the amount of storage space and/or data traffic provided to the Customer. If an agreed limit is exceeded, {companyName} is not obliged to facilitate sending, receiving, storing or changing any Content.</p>
      </li>
      <li>
      <p>{companyName} has the right to anonymise Content and to use it during and after termination of the Agreement for the improvement of the Platform and the Services and the development of new Services.</p>
      </li>
      </ol>
      <h3 id="article-11-protection-of-personal-data-and-confidential-information"><strong>Article 11 Protection of personal data and confidential information&nbsp;</strong></h3>
      <ol>
      <li>
      <p>{companyName} may process personal data of or provided by the Customer under the terms of the Agreement. Personal data will be processed in accordance with applicable laws and regulations, in accordance with {companyName}’s Privacy Policy, which the Customer agrees to upon the conclusion of the Agreement.</p>
      </li>
      <li>
      <p>The Customer declares and guarantees that he is authorised to provide and process the personal data as referred to in paragraph 1 of this Article to {companyName} with respect to the performance of the agreement.</p>
      </li>
      <li>
      <p>The Customer retains, as far as applicable, the title to the personal data provided to {companyName}.</p>
      </li>
      <li>
      <p>All employees who act under the authority of {companyName} and have access to the personal data are required to maintain confidentiality in writing.</p>
      </li>
      <li>
      <p>The Customer fully indemnifies {companyName} against all third-party claims, which arise in any way from and/or are related to the processing of personal data by {companyName} via the Platform and/or the Services, or by the third party providing services to {companyName} in this respect.&nbsp;</p>
      </li>
      </ol>
      <h3 id="article-12-intellectual-property-rights"><strong>Article 12 Intellectual property rights&nbsp;</strong></h3>
      <ol>
      <li>
      <p>All intellectual property rights that established in the Platform, the Services and any other items provided by virtue of the Agreement, are exclusively vested in {companyName} or its licensers. The Customer only obtains a right of use for the duration of the Agreement.</p>
      </li>
      <li>
      <p>The Customer is not permitted to remove or change any indication concerning the intellectual property rights of {companyName} in the Platform and the Services.</p>
      </li>
      <li>
      <p>Except as permitted by mandatory law, the Customer may not modify, reproduce or decompile the Platform or apply reverse engineering. The Customer is not permitted either to take any action with the purpose or purport to discover or obtain the source code of the Platform, or to engage third parties or to assist with such actions or for any indications concerning the confidential nature and confidentiality of works.</p>
      </li>
      <li>
      <p>{companyName} is allowed to take technical measures to protect its intellectual property rights. Technical safeguards fitted by {companyName} must not be removed or avoided.</p>
      </li>
      <li>
      <p>In the event that it has been conclusively established in court that the Platform and the Services infringe a third-party intellectual property right, or in the case that {companyName} believes there is a real chance that such an infringement is imminent, {companyName} will make sure that the Customer can continue to use the Platform and/or the Services (or an equivalent as for functionality) without disturbance. If, in {companyName}’s opinion, this is not reasonably possible, {companyName} has the right to dissolve the Agreement without being liable to pay damages to the Customer. Any other or further liability or indemnification obligation on the part of {companyName} for infringement of intellectual property of a third party is entirely excluded.</p>
      </li>
      </ol>
      <h3 id="article-13-obligations-with-regard-to-complaints"><strong>Article 13 Obligations with regard to complaints</strong></h3>
      <ol>
      <li>
      <p>If the Service delivered pursuant to the Agreement fails to comply with the Agreement, the Customer must notify {companyName} within a reasonable period of time after he has or reasonably should have discovered such failure. The notification is in any case deemed to be made on time if the Customer notifies {companyName} in writing within two months after the discovery.</p>
      </li>
      <li>
      <p>Complaints submitted to {companyName} will be answered within a period of 14 days from the date of receipt. If a complaint requires a longer processing time as anticipated, {companyName} will reply within the period of 14 days with a notice of receipt and an indication of when the Customer can expect a more detailed answer.</p>
      </li>
      <li>
      <p>If the complaint cannot be resolved by mutual agreement, a dispute may have to be submitted to the dispute resolution.</p>
      </li>
      </ol>
      <h3 id="article-14-liability"><strong>Article 14 Liability&nbsp;</strong></h3>
      <ol>
      <li>
      <p>{companyName} is not liable for a shortcoming in the fulfilment of the agreement if the Customer has not complied with the obligation of filing a complaint referred to in Article 13.</p>
      </li>
      <li>
      <p>In case of force majeure, {companyName} is not liable for a shortcoming in the fulfilment of the obligation to pay compensation. Force majeure means the provisions in article 15 of these Terms of Use.</p>
      </li>
      <li>
      <p>{companyName} excludes its liability to the maximum permissible situations in accordance with the provisions in imperative law with regard to the situation in which the Content and/or data of the Customer becomes damaged or becomes illegible. {companyName} is not obliged and cannot be held to repair the Content and/or data.</p>
      </li>
      <li>
      <p>The Customer indemnifies {companyName} against all claims comprising that the use of the Platform and/or the Services violates third-party rights and indemnifies {companyName} against all third-party claims resulting from non-compliance with these Terms of Use by Users.</p>
      </li>
      </ol>
      <h3 id="article-15-force-majeure"><strong>Article 15 Force Majeure</strong></h3>
      <ol>
      <li>
      <p>Force majeure means any shortcoming for which {companyName} cannot be held responsible, because it is not due to her fault and is not for her account under the law, a legal act or pursuant to generally accepted concepts in transactions.</p>
      </li>
      <li>
      <p>In the event of force majeure, {companyName} has the right to suspend the performance of the Agreement for the duration of the force majeure situation.</p>
      </li>
      <li>
      <p>If the situation of force majeure lasts for a period longer than 90 (ninety) days, both Parties are entitled to terminate the Agreement without being liable to pay damages to each other. All performance already made under the terms of the Agreement shall in that case be paid and settled.</p>
      </li>
      </ol>
      <h3 id="article-16-transfer-of-rights-and-obligations"><strong>Article 16 Transfer of rights and obligations</strong></h3>
      <ol>
      <li>The Customer is not entitled to transfer rights and obligations under the Agreement to a third party without {companyName}’s prior permission. {companyName} is allowed to transfer rights and obligations under the Agreement to a third party. The Customer is entitled to dissolve the Agreement in writing if {companyName} transfers its rights and obligations to a third party.&nbsp;</li>
      </ol>
      <h3 id="article-17-duration-extension-and-termination-implications"><strong>Article 17 Duration, extension and termination, implications&nbsp;</strong></h3>
      <ol>
      <li>
      <p>The Agreement is entered into for the duration of one calendar month and subsequently it is each time renewed tacitly for the duration of one calendar month.</p>
      </li>
      <li>
      <p>{companyName} and the Customer both are entitled to terminate the Agreement with a one-month notice period.</p>
      </li>
      <li>
      <p>Both parties are entitled to dissolve the Agreement without written notice of default or intervention of the court and without being obliged to pay any damages or compensation with immediate effect in writing in whole or in part in case:</p>
      </li>
      <li>
      <p>the other Party applies for a moratorium;</p>
      </li>
      <li>
      <p>a Party applies for its bankruptcy, its bankruptcy is applied for or pronounced;</p>
      </li>
      <li>
      <p>(part of) the assets of the other Party has/have been or is/are being seized;</p>
      </li>
      <li>
      <p>in the event that the other Party is deemed to no longer being able to meet its obligations.</p>
      </li>
      <li>
      <p>Both Parties have the right to terminate the Agreement in whole or in part in the event the other Party fails to fulfil its obligations under the Agreement for which failure it can be held responsible and, after a proper and detailed written notice by registered post with at least a 14- (fourteen) day period in which fulfilment is still requested, the defaulting party continues to fail to fulfil its obligations under the Agreement.</p>
      </li>
      <li>
      <p>If the Customer has already received any performance in connection with the performance of the Agreement at the time of dissolution, such performance and the associated payment obligations shall not be subject to reversal, unless {companyName} is in default with respect to said performance. Any amounts that {companyName} has invoiced before the dissolution in connection with any performance already delivered under the terms of the Agreement, remain due and become immediately due and payable at the moment of the dissolution.</p>
      </li>
      <li>
      <p>From the moment the Agreement ends, all the Customer’s rights of use on the Platform, the Service and any times provided by {companyName}, are cancelled.&nbsp;</p>
      </li>
      </ol>
      <h3 id="article-18-miscellaneous"><strong>Article 18 Miscellaneous</strong></h3>
      <ol>
      <li>
      <p>If any provision in the Agreement and/or these Terms of Use is void or nullifiable, the remaining provisions will remain in full force. {companyName} will establish a new provision, which shall be as much as possible in keeping with the purport of the void/annulled provision.</p>
      </li>
      <li>
      <p>If at any time {companyName} does not invoke a right or power conferred on it by virtue of the Agreement or the law, this does not constitute a waiver of such right or power.</p>
      </li>
      <li>
      <p>Provisions in the agreement and these Terms of Use which by their nature are intended to remain applicable after the end of the Agreement remain fully effective upon termination of the Agreement.</p>
      </li>
      <li>
      <p>{jurisdiction} law applies to the Agreement, these Terms of Use and all the use of the Platform and the Services.</p>
      </li>
      </ol>
      <div class="documents__bottom"><div class="documents__bottom-address">
      <CompanyContactInfo className='mt-5'/>
      </div><div class="documents__bottom-updated">Terms of Service was last updated on {lastUpdated}</div>
      </div>
    </div>
  )
}

const pageName = 'Terms of Service'

const TOS = () => {
  trackPage(pageName)
  return (
    <Layout>
      <SEO title={pageName}/>
      <AnalyticsHelmet/>
      <section className='pt-8 pt-md-11 pb-8 pb-md-14'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-12 col-md'>
              <Content/>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default TOS
